
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts'
import moment from "moment";
import userApi from '@/api/common/users';
import chartDataApi from '@/api/common/chartData';
import {
	countSpline, colorSun, colorSat, colorNormal
} from "@/constant/dashboardChartOptions";
//import Trend from "vuetrend";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBoxWithoutLoading from '@/components/SkeletonBoxWithoutLoading';
//const numeral = require('numeral');
const _ = require('lodash');
//const colorSun = '#f00';
//const colorSat = '#fd7e14';
//const colorNormal = '#000';

export default {
    components: {
		highcharts: Chart,
		sparkChart: lazyLoadComponent({
			componentFactory: () => import("@/views/reportPrime/sparkChart.vue"),
			loading: SkeletonBoxWithoutLoading,
		}),
    },
    data() {
		return {
			isLoading: false,
			querySearch: '',
			primeUserList: [],
			selectedUser: null,
			chartOptions: {
				chart: {
					type: 'line',
					zoomType: 'x',
					panning: true,
					panKey: 'shift',
					scrollablePlotArea: {
						minWidth: 100
					},
					height: '300px',
					marginRight: 30,
					animation: Highcharts.svg
				},
				credits: {
					enabled: false,
				},
				title: {
					text: '',
				},
				yAxis: {
					title: {
						text: '',
					},
					labels: {
						formatter: function () {
							return Highcharts.numberFormat(this.value, 0);
						}
					}
				},
				xAxis: {
					categories: [],
					title: {
						text: '',
					},
					allowDecimals: false,
					//tickPixelInterval: 30,
					labels: {
						formatter: function () {
							let color = (moment.utc(this.value, 'DD-MM-YYYY').day() === 0) ? colorSun : ((moment.utc(this.value, 'DD-MM-YYYY').day() === 6) ? colorSat : colorNormal)
							return `<span style="fill: ${color};">${this.value}</span>`;
						}
					}
				},
				legend: { enabled: false },
				plotOptions: {
					spline: {
						dataLabels: {
							enabled: true
						},
					},
					series: {
						tooltip: {
							headerFormat: '{point.x} <b>{series.name}</b><br>',
							pointFormat: 'Used {point.y} times',
							clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
						},
					},
					line: {
						dataLabels: {
							enabled: true
						},
						enableMouseTracking: false,
						marker: {
							fillColor: Highcharts.getOptions().colors[2],
							lineColor: Highcharts.getOptions().colors[2],
							enabled: false
						}
					}
				},
				series: [{
					name: 'None',
					data: []
				}]
			},
			debounceGetPrimeUserList: null,
			isLoadingVipRechargeStatistics: false,
			vipRechargeStatistics: [],
			vipRechargeStatisticsChartOptions: Object.assign(JSON.parse(JSON.stringify(countSpline)), {
				plotOptions: {
					spline: {
						dataLabels: {
							enabled: true
						},
					},
					series: {
						tooltip: {
							headerFormat: '{point.x} <b>{series.name}</b><br>',
							pointFormat: 'Recharged ${point.y}',
							clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
						},
					},
					line: {
						dataLabels: {
							enabled: true
						},
						enableMouseTracking: false,
						marker: {
							fillColor: Highcharts.getOptions().colors[2],
							lineColor: Highcharts.getOptions().colors[2],
							enabled: false
						}
					}
				},
			}),
			typeOfChart: 0,
        };
    },

    mounted() {
		this.getPrimeUserList();
		this.getVipRechargeStatistics();
		this.debounceGetPrimeUserList = _.debounce(function () {
			this.getPrimeUserList();
		}, 1000);
    },
    methods: {
		getPrimeUserList() {
			this.isLoading = true;
			userApi.getPrimeUserList(this.querySearch)
				.then((response) => {
					if (response.data.result === 0) {
						this.primeUserList = response.data.data;
						if (this.primeUserList.length > 0) {
							this.getPrimeUserReportData(this.primeUserList[0]);
						}
					}
					else {
						console.error(response.data);
					}
					this.isLoading = false;
				})
				.catch((error) => {
					console.error(error);
					this.isLoading = false;
				});
		},
		getPrimeUserReportData(userItem) {
			this.selectedUser = userItem;
			this.isLoading = true;
			userApi.getPrimeUserReportDataFull(userItem.id)
				.then((response) => {
					if (response.data.result === 0) {
						//userItem.primeUserReportData = response.data.data;
						this.chartOptions.xAxis.categories = [];
						this.chartOptions.xAxis.categories = response.data.data.map(x => moment.utc(x.transTime).format('DD-MM-YYYY'));
						this.chartOptions.series = [{
							name: userItem.username,
							data: response.data.data.map(x => {
								return x.amountInt;
							})
						}];
					}
					else {
						console.error(response.data);
					}
					this.isLoading = false;
				})
				.catch((error) => {
					console.error(error);
					this.isLoading = false;
				});
		},
		clearSearch() {
			this.querySearch = '';
			this.getPrimeUserList();
		},
		getVipRechargeStatistics() {
			this.isLoadingVipRechargeStatistics = true;
			chartDataApi.getVipRechargeStatistics()
				.then((response) => {
					if (response.data.result === 0) {
						this.vipRechargeStatistics = response.data.data;
						this.vipRechargeStatisticsChartOptions.xAxis.categories = [];
						this.vipRechargeStatisticsChartOptions.xAxis.categories = response.data.data.map(x => x.MonthView);
						this.vipRechargeStatisticsChartOptions.series = [{
							name: "VIP + PRIME (>= $1500)",
							data: response.data.data.map(x => {
								return x.TotalRechargeAmountVip;
							})
						},
						{
							name: "TOTAL",
							data: response.data.data.map(x => {
								return x.TotalRechargeAmount;
							})
						}];
					}
					else {
						console.error(response.data);
					}
					this.isLoadingVipRechargeStatistics = false;
				})
				.catch((error) => {
					console.error(error);
					this.isLoadingVipRechargeStatistics = false;
				});
		}
    }
}